import React from "react"

export default function() {
  return (
    <div style={{ padding: 100 }}>
      <div>
        <h1>Privacy</h1>
        <p>
          Effective as of May 10, 2020, LANGAPI COMPANY, (“Prowess”) and its
          subsidiaries (collectively, the “Prowess Group” or “we” or “us” or
          “our”) have updated our Privacy Policy (“Policy”).
        </p>
        <h1>1. Introduction</h1>
        <p>
          This Policy details our commitment to protecting the privacy of
          individuals who visit our Websites (as herein defined) (“Website
          Visitors”), who are authorized to use our Services through a
          Subscriber’s Account (“Users”), or who attend or register to attend
          events which we organize or co-organize (“Attendees”). For the
          purposes of this Policy, the term, “Websites”, shall refer
          collectively to www.Prowess.com as well as the other websites that the
          Prowess Group operates and that link to this Policy, the term
          “Services” shall refer to our on-demand product excellence system, and
          tools and services made available from the Websites. The term
          “Subscriber” shall refer to an individual or an entity that has agreed
          to a Service Agreement for use of our Services, including an
          individual representing the Subscribing company, the term “Account”
          shall mean all Prowess accounts or instances created by or on behalf
          of Subscriber within the Service.
        </p>
        <h1>2. Scope Of This Policy</h1>
        <p>
          In this Policy, personal information means information relating to an
          identified or identifiable natural person. An identifiable person is
          one who can be identified, directly or indirectly, in particular by
          reference to an identification number or to one or more factors
          specific to his/her physical, physiological, mental, economic,
          cultural or social identity. Personal information does not include
          “Usage Data” which we define as encoded or anonymized information or
          aggregated data we collect about a group or category of services,
          features or users which does not contain personally identifying
          information. Usage Data helps us understand trends in usage of the
          Service so that we can better consider new features or otherwise
          tailor the Service. In addition to collecting and using Usage Data
          ourselves, we may share Usage Data with third parties, including our
          customers, partners and service providers, for various purposes,
          including to help us better understand our customers’ needs and
          improve the Service as well as for advertising and marketing purposes.
          We do not share Usage Data with third parties in a way that would
          enable them to identify you personally.
        </p>
        <p>
          Our Websites may contain links to other websites and the information
          practices and the content of such other websites are governed by the
          privacy statements of such other websites. We encourage you to review
          the privacy statements of any such other websites to understand their
          information practices.
        </p>
        <p>
          If you have purchased a subscription to our Services additional terms
          relating to data rights are set forth in and governed by our Terms of
          Service, available here: https://www.Prowess.app/terms, or such other
          applicable agreement between you and any member of the Prowess Group
          relating to your access to and use of such Services (“Service
          Agreement”). We may process certain personal data as a data processor
          for our Subscribers. Subscribers to our Services act as data
          controllers and are solely responsible for establishing policies for
          and ensuring compliance with all applicable laws and regulations, as
          well as any and all privacy policies, agreements or other obligations,
          relating to the collection of personal information in connection with
          the use of our Services by individuals with whom our Subscribers
          interact. If you are an individual who interacts with a Subscriber
          using our Services, then you will be directed to contact our
          Subscriber for assistance with any requests or questions relating to
          your personal information processed in connection with such
          interaction. We may transfer personal information to companies that
          help us provide our Services to Subscribers. Our processing of
          personal data under the direction of our Subscribers and transfers to
          subsequent third parties for these purposes are governed by the
          Service Agreements with our Subscribers, including the data processing
          agreement.
        </p>
        <h1>3. Information That You Provide To Us</h1>
        <p>Account and Registration Information:</p>
        <p>
          If you are Subscriber or User, we ask for and collect personal
          information about you and your Users such as name, address, phone
          number, email address, instant messaging ID, and credit card
          information of the Subscriber, as well as certain related information
          like Subscriber company name and website name email address and name
          or alias from each User. If you sign-up for a free trial Account, you
          are not required to enter your credit card information unless and
          until you decide to continue with a paid subscription to our Services.
          A third-party intermediary is used to manage credit card processing.
        </p>
        <p>
          We refer to any information described above as “Account Information”
          for the purposes of this Policy. By voluntarily providing us with
          Account Information, you represent that you are the owner of such
          personal data or are otherwise authorized to provide it to us,
          specifically, if you as a Subscriber provide us Account Information
          related to the User, you represent that the User has not objected to
          such processing.
        </p>
        <p>Submissions by the Website Visitor:</p>
        <p>
          We ask for and collect personal information from you when you submit
          web forms on our Websites or as you use interactive features of the
          Websites, including, participation in surveys, contests, promotions,
          sweepstakes, requesting customer support, or communicating with us.
        </p>
        <p>Attendee Information:</p>
        <p>
          We ask for and collect personal information such as your name,
          address, phone number and email address when you register for or
          attend an event as an Attendee.
        </p>
        <h1>4. Information That We Collect From You on our Websites</h1>
        <p>Cookies and Other Tracking Technologies:</p>
        <p>
          We and our authorized partners use cookies and other information
          gathering technologies for a variety of purposes. These technologies
          may provide us with personal information, information about devices
          and networks you utilize to access our Websites, and other information
          regarding your interactions with our Websites.
        </p>
        <p>
          We partner with third parties to either display advertising on the
          Websites or to manage our advertising on other sites. Our third party
          partners may use technologies such as cookies to gather information
          about your activities on our Websites and other sites in order to
          suggest advertisements based upon your browsing activities and
          interests. If you wish to not have this information used for the
          purpose of serving you interest-based ads, you may opt-out by
          contacting eric@prowess.app. Please note this does not opt you out of
          being served ads and you will continue to receive generic ads.
        </p>
        <p>
          Web beacons, tags and scripts may be used on our Websites or in email
          or other electronic communications we send to you. These assist us in
          delivering cookies, counting visits to our Websites, understanding
          usage and campaign effectiveness and determining whether an email has
          been opened and acted upon. We may receive reports based on the use of
          these technologies by our third-party service providers on an
          individual and aggregated basis.
        </p>
        <p>
          We use Local Storage Objects (“LSOs”) such as HTML5 to store content
          information and preferences. Various browsers may offer their own
          management tools for removing HTML5 LSOs. Third parties with whom we
          partner to provide certain features on our Websites or to display
          advertising based upon your Web browsing activity use LSOs such as
          HTML5 and Flash to collect and store information. For further
          information on how to manage Flash LSOs see Flash Player Help.
        </p>
        <p>Logs:</p>
        <p>
          As is true with most websites and services delivered over the
          Internet, we gather certain information and store it in log files when
          you interact with our Websites and Services. This information includes
          internet protocol (IP) addresses as well as browser type, internet
          service provider, URLs of referring/exit pages, operating system,
          date/time stamp, information you search for, locale and language
          preferences, identification numbers associated with your devices, your
          mobile carrier, and system configuration information. Occasionally, we
          connect personal information to information gathered in our log files
          as necessary to improve our Websites and Services. In such a case, we
          would treat the combined information in accordance with this Policy.
        </p>
        <p>Analytics:</p>
        <p>
          We collect analytics information when you use the Websites to help us
          improve them. We may also share anonymous data about your actions on
          our Websites with third-party service providers of analytics services.
        </p>
        <h1>5. Information Collected From Other Sources</h1>

        <p>Social Media Widgets:</p>
        <p>
          The Websites include social media features, such as the Facebook Like
          button, and widgets, such as the Share This button or interactive
          mini-programs that run on our Websites. These features may collect
          your Internet protocol address, which page you are visiting on the
          Websites, and may set a cookie to enable the feature to function
          properly. Social media features and widgets are either hosted by a
          third party or hosted directly on the Websites. Your interactions with
          these features are governed by the privacy statement of the companies
          that provide them.
        </p>
        <p>Single Sign-On:</p>
        <p>
          You can log into certain Services using sign-in services such as
          Google Authentication or SAML. These services will authenticate your
          identity, without the need to sign in with a username and password
          combination.
        </p>
        <p>Information From Third Party Services:</p>
        <p>
          We may also obtain other information, including personal information,
          from third parties and combine that with information we collect
          through our Websites. For example, we may have access to certain
          information from a third party social media or authentication service
          if you log into our Services through such a service or otherwise
          provide us with access to information from the service. Any access
          that we may have to such information from a third party social media
          or authentication service is in accordance with the authorization
          procedures determined by that service. By authorizing us to connect
          with a third party service, you authorize us to access and store your
          name, email address(es), current city, profile picture URL, and other
          personal information that the third party service makes available to
          us, and to use and disclose it in accordance with this Policy. You
          should check your privacy settings on these third party services to
          understand and change the information sent to us through these
          services. For example, you can log in to the Services using sign-in
          services such as Google Authentication, as further described below.
        </p>
        <p>Customer Data:</p>
        <p>
          Our Services enable Prowess customers to connect their CRM and
          customer contacts, and we use data from these records such as deal
          size (revenue), deal status, and customer industry (collectively,
          “Customer Data”).
        </p>
        <h1>
          6. How We Use Information That We Collect (Purpose of the processing)
        </h1>
        <p>
          We use the information we collect about you (including personal
          information, to the extent applicable) to (a) provide, operate,
          maintain and improve the Services; (b) enable you to access and use
          the Services; (c) process and complete transactions, and send you
          related information, including purchase confirmations and invoices;
          (d) send transactional messages, including responses to your comments,
          questions, and requests; provide customer service and support; and
          send you technical notices, updates, security alerts, and support and
          administrative messages; (e) send promotional communications, such as
          providing you with information about products and services, features,
          surveys, newsletters, offers, promotions, contests, and events; and
          provide other news or information about us and our partners. (f)
          process and deliver contest or sweepstakes entries and rewards; (g)
          monitor and analyze trends, usage, and activities in connection with
          the Websites and Services and for marketing or advertising purposes;
          (h) investigate and prevent fraudulent transactions, unauthorized
          access to the Services, and other illegal activities; (i) personalize
          the Websites and Services, including by providing features or
          advertisements that match your interests and preferences; and (j)
          comply with our legal obligations, including our obligations related
          to personal data protection.
        </p>
        <h1>7. Legal grounds for processing</h1>
        <p>
          We process your information for the purposes described in this Policy,
          based on the following legal grounds:
        </p>
        <p>(i) When we’re pursuing legitimate interests</p>
        <p>
          We process your information for our legitimate interests and those of
          third parties. This means that we process your information for things
          like: Providing, operating; maintaining, and improving our Services;
          enabling you to access and use the Services; promoting the Services;
          sending promotional communications, monitoring and analyzing trends,
          usage, and activities in connection with the Websites and Services;
          investigating and preventing fraudulent transactions, unauthorized
          access to the Services, and other illegal activities; (i)
          personalizing the Websites and Services.
        </p>
        <p>(ii) When we’re providing a service</p>
        <p>
          We process your data to provide a service you’ve asked for under the
          Service Contract or other contract between you and us. This means that
          we process your information for things like: Enabling you to access
          and use the Services; processing transactions, and sending you related
          information; providing customer service and support; personalizing the
          Websites and Services. (iii) When we’re complying with legal
          obligations
        </p>
        <p>
          We’ll process your data when we have a legal obligation to do so, for
          example, if we’re responding to legal process or an enforceable
          governmental request. (iv) With your consent
        </p>
        <p>
          We may ask for your agreement to process your information for specific
          purposes and you have the right to withdraw your consent at any time.
          For example, we ask for your consent to publish your testimonial, if
          it includes your identification. If you wish to withdraw your consent,
          you can contact us at privacy@Prowess.app.
        </p>
        <h1>8. Sharing Of Information Collected</h1>
        <p>Third-Party Service Providers:</p>
        <p>
          We share information, including personal information, with our
          third-party service providers that we use to provide hosting for and
          maintenance of our Websites, application development, backup, storage,
          payment processing, analytics and other services for us. These
          third-party service providers may have access to or process your
          personal information for the purpose of providing these services for
          us. We do not permit our third-party service providers to use the
          personal information that we share with them for their marketing
          purposes or for any other purpose than in connection with the services
          they provide to us.
        </p>
        <p>
          Compliance with Laws and Law Enforcement Requests; Protection of Our
          Rights:
        </p>
        <p>
          In certain situations, we may be required to disclose personal
          information in response to lawful requests by public authorities,
          including to meet national security or law enforcement requirements.
          We may disclose personal information to respond to subpoenas, court
          orders, or legal process, or to establish or exercise our legal rights
          or defend against legal claims. We may also share such information if
          we believe it is necessary in order to investigate, prevent, or take
          action regarding illegal activities, suspected fraud, situations
          involving potential threats to the physical safety of any person,
          violations of our Service Agreement, or as otherwise required by law.
        </p>
        <p>Testimonials:</p>
        <p>
          From time to time, we may post testimonials on the Websites that may
          contain personal information. We obtain your consent to post your name
          along with your testimonial. If you wish to update or delete your
          testimonial, you can contact us at privacy@Prowess.app.
        </p>
        <p>Referrals:</p>
        <p>
          If you choose to use our referral service to tell a friend about our
          products and services, we will ask you for your friend’s name and
          email address. We will automatically send your friend an email
          inviting him or her to visit the Websites and will store this
          information for the purpose of sending this initial email, tracking
          the success of our referral program and other marketing activities.
          Your referral may contact us at privacy@Prowess.app to request that we
          remove his/her information from our database.
        </p>
        <p>Community Forums:</p>
        <p>
          The Websites may offer publicly accessible blogs, community forums,
          comments sections, discussion forums, or other interactive features
          (“Interactive Areas”). You should be aware that any information that
          you post in an Interactive Area might be read, collected, and used by
          others who access it. To request removal of your personal information
          from an Interactive Area, contact us at privacy@Prowess.app. In some
          cases, we may not be able to remove your personal information, in
          which case we will let you know if we are unable to do so and why.
        </p>
        <p>Prowess Group Sharing:</p>
        <p>
          We may share information, including personal information, with any
          member of the Prowess Group.
        </p>
        <p>With Your Consent:</p>
        <p>
          We may also share personal information with third parties when we have
          your consent to do so.
        </p>
        <h1>9. International Transfer Of Information Collected</h1>
        <p>
          We primarily store personal information about Website Visitors and
          Subscribers within the United States.
        </p>
        <h1>10. Choice and Communications Preferences</h1>
        <p>
          We offer those who provide personal contact information a means to
          choose how we use the information provided. You can opt-out of
          receiving marketing and non-transactional communications by clicking
          on the “unsubscribe” link located on the bottom of our marketing
          emails or you may send a request to privacy@Prowess.app.
        </p>
        <h1>
          11. Accessing, Exporting and Removing Your Information and Other
          Available Rights
        </h1>
        <p>
          Upon request, we will provide you with information about whether we
          hold and otherwise process, or process on behalf of a third party, any
          of your personal information. To request this information please
          contact us at privacy@Prowess.app. Subscribers to our Services may
          update or change their Account Information by editing their profile or
          organization record or by contacting support@Prowess.com for more
          detailed instructions. To make a request to have personal information
          maintained by us returned to you or removed, please email
          support@Prowess.com. Requests to access, change, or remove your
          information will be handled within 30 days.
        </p>
        <p>
          An individual who seeks access to, or who seeks to correct, amend, or
          delete inaccuracies in personal information stored or processed by us
          on behalf of a Subscriber should direct his/her query to the
          Subscriber (the data controller). Upon receipt of a request from one
          of our Subscribers for us to remove the data, we will respond to their
          request within thirty (30) days. We will retain personal information
          that we store and process on behalf of our Subscribers for as long as
          needed to provide the Services to our Subscribers. We will retain and
          use this personal information as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements.
        </p>
        <p>
          If you are a Subscriber or otherwise provide us with personal
          information in connection with your use of our Websites or Services,
          we will delete this information upon your request, provided that,
          notwithstanding such request, this information may be retained for as
          long as you maintain an account for our Services, or as needed to
          provide you with our Services (in particular for Accounts with
          multiple Users where we need to retain the interaction with Users who
          continue to use the Services), comply with our legal obligations,
          resolve disputes and enforce our agreements.
        </p>
        <p>
          If you reside in EEA and provided us with your personal information
          based on a contract with us, you may ask us to export such personal
          information in a structured, commonly used and machine-readable format
          and you have the right to transmit those data to another controller.
          To request such export please contact us at help@Prowess.app.
        </p>
        <p>
          If you reside in EEA, you may object to the way and scope how we
          process your personal data. To do so please contact us at
          privacy@Prowess.app. You can object to receiving of marketing and
          non-transactional communications also by clicking on the “unsubscribe”
          link located on the bottom of our marketing emails.
        </p>
        <p>
          If you reside in EEA, you may lodge a complaint with a local
          supervisory authority if you consider that our processing of your
          personal data relating infringes applicable data protection laws. We
          would be happy if you reach to us before filing the complaint.
        </p>
        <h1>12. Security</h1>
        <p>
          The security of your personal information is important to us. We
          follow generally accepted, reasonable, and appropriate standards to
          protect the personal information submitted to us, both during
          transmission and once it is received. In deciding what is reasonable
          and appropriate we take into account the risks involved and the nature
          of the information. If you have any questions about the security of
          your personal information, you can contact us at privacy@Prowess.app.
        </p>
        <h1>13. Children’s Personal Information</h1>
        <p>
          We do not knowingly collect any personal information from children
          under the age of 16. If you are under the age of 16, please do not use
          or submit any personal information through our Websites or Services.
          We encourage parents and legal guardians to monitor their children’s
          Internet usage and to help enforce this Policy by instructing their
          children never to provide personal information through the Websites or
          Services without parental permission. If you have reason to believe
          that a child under the age of 16 has provided personal information to
          us through the Websites or Services, please contact us at
          privacy@Prowess.app, and we will use commercially reasonable efforts
          to delete that information.
        </p>
        <h1>14. Business Transactions</h1>
        <p>
          We may assign or transfer this Policy, as well as your account and
          related information and data, including any personal information, to
          any person or entity that acquires all or substantially all of our
          business, stock or assets, or with whom we merge.
        </p>
        <h1>15. Changes To This Policy</h1>
        <p>
          If there are any material changes to this Policy, you will be notified
          by our posting of a prominent notice on the Websites prior to the
          change becoming effective. We encourage you to periodically review
          this page for the latest information on our privacy practices. Your
          continued use of the Websites or the Services constitutes your
          agreement to be bound by such changes to this Policy. Your only
          remedy, if you do not accept the terms of this Policy, is to
          discontinue use of the Websites and the Services.
        </p>
        <h1>16. Contact Us</h1>
        <p>
          If you have questions regarding this Policy or about the privacy
          practices of Prowess, please contact us by email at
          privacy@Prowess.app, or at: Prowess.
        </p>
        <p>
          Attn: Privacy Officer 544 Guerrero Street #3 San Francisco, CA 94110
          United States of America
        </p>
      </div>
    </div>
  )
}
